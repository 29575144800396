const config = {
    WIN_GLOBAL_PAY : {
        API_KEY: 'pk_070b-2b706d-6c0-68521',
        GID: 'hjyou02',
        TID: 'WGP325101',
        SERIAL_NUMBER: '673928'
    }
}

export default config

/**
 * [쿠폰나라]
 * 1. https://couponnara.co.kr/shop/detail/11
 * 2. https://couponnara.co.kr/shop/detail/12
 * 온라인 key :pk_070b-2b706d-6c0-68521
 * GID : hjyou02
 * TID : WGP325101
 * 일련번호 : 673928
 */